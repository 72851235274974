module.exports = {
  api: {
    baseUrl: 'https://api-staging.soli-infra.de/',
    clientSecret: 'ff0yO7H8bLxYBzXI0K6EhYHh0mbUHfAzoNv4x51q',
    clientId: 4
  },
  evergabe: {
    url: 'https://api-staging.soli-infra.de',
    username: 'evergabe',
    password: 'secret'
  }
}
